export default {
  offerTitles: {
    discount: {
      en: 'Try first month for {priceWithDiscount} <s>{price}</s>',
    },
    freeTrial: {
      en: 'Get free month + case',
    },
    freeProduct: {
      en: 'Get free fragrance + case',
    },
    free2ndMonth: {
      en: 'Get free second month + case',
    },
    noOffer: {
      en: 'Try first month for {price}',
    },
  },
  selectBlockTitle: {
    en: 'Exclusive access for scentbird subscribers',
  },
  selectBlockDescription: {
    en: `
    Discover our curated collection of premium perfumes and colognes from the most exclusive fragrance houses,<br class="onlyDesktop"/> hand-picked by our team of scent experts.
    Receive a black atomizer with every Scentbird Select scent.
    `,
  },
}
