import React from 'react'
import { track } from 'analytics'
import { useIntl } from 'intl'
import links from 'links'

import { useSubscribeOffer } from 'modules/subscription'

import { Button, buttonMessages } from 'components/inputs'
import type { ButtonProps } from 'components/inputs'


export type SubscribeButtonProps =
  Partial<
    Pick<
      ButtonProps,
      'className' | 'title' | 'style' | 'size' | 'width' | 'fullWidth' | 'fullWidthOnMobile' | 'loading' | 'onClick'
      | 'data-testid' | 'data-cnstrc-item-variation-id'
    >
  >
  & {
    placement: string
    trackParams?: Record<string, any>
    'data-cnstrc-upcharge'?: number
  }

const SubscribeButton: React.FunctionComponent<SubscribeButtonProps> = (props) => {
  const {
    className,
    title,
    style = 'primary',
    size = 56,
    width,
    fullWidth,
    fullWidthOnMobile,
    placement,
    trackParams,
    loading,
    'data-testid': dataTestId = 'subscribeButton',
    'data-cnstrc-item-variation-id': dataCnstrcItemVariationId,
    'data-cnstrc-upcharge': dataCnstrcUpcharge,
    onClick,
  } = props

  const intl = useIntl()

  const {
    offerType,
    price: offerPrice,
    priceWithDiscount: offerPriceWithDiscount,
    extraShippingPrice: offerExtraShippingPrice,
    formattedPrice,
    formattedPriceWithDiscount,
    formattedExtraShippingPrice,
    eventDiscountText,
    couponCode: coupon,
    isFetching,
    handleSubscribeClick,
  } = useSubscribeOffer()

  const freeTrialPrice = offerType === 'freeTrial' ? formattedExtraShippingPrice : null
  const price = freeTrialPrice || formattedPriceWithDiscount || formattedPrice

  const cnstrcPrice = offerType === 'freeTrial' ? offerExtraShippingPrice : (offerPriceWithDiscount || offerPrice)
  const cnstrcFormattedPrice = cnstrcPrice ? intl.formatPrice(cnstrcPrice + (dataCnstrcUpcharge || 0), { withCurrency: false }) : ''

  return (
    <Button
      className={className}
      title={title || { ...buttonMessages.subscribeForPrice, values: { price } }}
      size={size}
      style={style}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      loading={isFetching || loading}
      to={links.subscribe}
      onClick={(event) => {
        track('Banner click', {
          ...trackParams,
          placement,
          content: 'Subscribe',
          action: 'link',
          link: links.subscribe,
          discount: eventDiscountText,
          coupon,
        })

        handleSubscribeClick()

        if (typeof onClick === 'function') {
          onClick(event)
        }
      }}
      data-testid={dataTestId}
      // TODO: Improve the flow by making sure that this property is used only within the product description — added on 12–02–2024 by algeas
      data-cnstrc-btn="subscribe"
      data-cnstrc-item-price={cnstrcFormattedPrice}
      data-cnstrc-item-variation-id={dataCnstrcItemVariationId}
      html
    />
  )
}


export default SubscribeButton

