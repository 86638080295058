import { useAb } from 'hooks'
import { constants } from 'helpers'

import useProductCollaboration, { type UseProductCollaborationProps } from './useProductCollaboration'

import messages from './messages'


type UseProductCollaborationLabelProps = UseProductCollaborationProps & {
  isShortLabelText?: boolean
}

const useProductCollaborationLabel = ({ labels, isShortLabelText }: UseProductCollaborationLabelProps ) => {
  const collaboration = useProductCollaboration({ labels })
  const isPremiumLabelEnabled = useAb(constants.abTests.pcPremiumLabel) === 'b'
  const isTherePremiumLabel = labels?.extra?.some((label) => [ 'SAKS_SELECTED', 'UPCHARGE' ].includes(label))

  if (isPremiumLabelEnabled && isTherePremiumLabel) {
    return messages.premium
  }

  if (collaboration === 'saks') {
    if (isShortLabelText) {
      return messages.shortText
    }

    return messages.default
  }
}

export default useProductCollaborationLabel
