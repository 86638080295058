export default {
  default: {
    en: 'Scentbird Select',
  },
  shortText: {
    en: 'SB select',
  },
  premium: {
    en: 'Premium',
  },
}
